import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom-v5-compat";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import DehazeIcon from "@mui/icons-material/Dehaze";
import LoginIcon from "@mui/icons-material/Login";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SubjectIcon from "@mui/icons-material/Subject";
import RateReviewIcon from "@mui/icons-material/RateReview";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import leftArrows from "../../../assets/leftArrows.svg";
import Joyride, { CallBackProps, EVENTS, STATUS } from "react-joyride";
import {
  LoginModal,
  RegisterModal,
  PaymentModal,
  Typography,
} from "../../../components";
import { AuthContext } from "../../../contexts/auth";
import { ERoutesPath } from "../../../routes";

import { TUTORIAL_STEPS } from "./helper";
import { EModalType, TSidebarProps } from "./types";
import * as S from "./styles";

export const Sidebar = ({ triggerTutorial }: TSidebarProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isFeedbackQuestion, isUserLogged, isPaidActive, signOut } =
    useContext(AuthContext);
  const [expanded, setExpanded] = useState(false);
  const [modalActive, setModalActive] = useState<EModalType>();
  const [isActiveTutorial, setIsActiveTutorial] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const handleFinishTutorial = () => {
    setIsActiveTutorial(false);
    setCurrentStep(0);
  };
  const toggleDrawer = () => {
    if (expanded) handleFinishTutorial();

    setExpanded(!expanded);
  };

  const handleNavigate = (to: ERoutesPath) => {
    toggleDrawer();
    navigate(to);
  };

  const handleModal = (modalType: EModalType) => {
    setModalActive(modalType);
    setExpanded(!expanded);
  };

  const handleSignOut = () => {
    signOut();
    navigate(ERoutesPath.ROOT);
  };

  useEffect(() => {
    if (isUserLogged && isPaidActive) {
      const hasCompletedTutorial = localStorage.getItem("REVISE_TUTORIAL");

      if (hasCompletedTutorial !== "true") {
        setIsActiveTutorial(true);
        localStorage.setItem("REVISE_TUTORIAL", "true");
      }
    }
  }, [isUserLogged, isPaidActive]);

  const MenuOptions = () => (
    <S.Container>
      <S.CloseDrawer onClick={toggleDrawer}>
        <img src={leftArrows} alt="Recolher menu" />
        <Typography variant="small" color="#78909d">
          Recolher barra
        </Typography>
      </S.CloseDrawer>

      <S.MenuItems>
        {!isUserLogged && (
          <S.RegisterInfo>
            <Typography variant="small" color="#78909d" align="center">
              Cadastre-se agora e tenha acesso a várias questões e ao nosso
              método de revisão
            </Typography>
          </S.RegisterInfo>
        )}

        <S.ButtonLink
          onClick={() => handleNavigate(ERoutesPath.ROOT)}
          isActive={pathname === ERoutesPath.ROOT}
          className="tutorial-home"
        >
          <SubjectIcon fontSize="medium" style={{ color: "#78909D" }} />
          <Typography color="#78909D">Início</Typography>
        </S.ButtonLink>

        {!isUserLogged && (
          <>
            <S.ButtonLink onClick={() => handleModal(EModalType.LOGIN)}>
              <LoginIcon fontSize="medium" style={{ color: "#78909D" }} />
              <Typography color="#78909D">Entrar</Typography>
            </S.ButtonLink>

            <S.ButtonLink onClick={() => handleModal(EModalType.REGISTER)}>
              <AppRegistrationIcon
                fontSize="medium"
                style={{ color: "#78909D" }}
              />
              <Typography color="#78909D">Cadastre-se</Typography>
            </S.ButtonLink>
          </>
        )}

        {isUserLogged && isPaidActive && (
          <S.ButtonLink
            onClick={() => handleNavigate(ERoutesPath.QUESTOES)}
            isActive={pathname === ERoutesPath.QUESTOES}
            className="tutorial-question"
          >
            <MenuBookIcon fontSize="medium" style={{ color: "#78909D" }} />
            <Typography color="#78909D">Resolver questões</Typography>
          </S.ButtonLink>
        )}

        {isFeedbackQuestion && (
          <S.ButtonLink
            onClick={() => handleNavigate(ERoutesPath.REVISOES)}
            isActive={pathname === ERoutesPath.REVISOES}
            className="tutorial-reviews"
          >
            <RateReviewIcon fontSize="medium" style={{ color: "#78909D" }} />
            <Typography color="#78909D">Cronograma de revisões</Typography>
          </S.ButtonLink>
        )}

        {isUserLogged && isPaidActive && (
          <S.ButtonLink
            onClick={() => handleNavigate(ERoutesPath.REVISE_RAPIDO)}
            isActive={pathname === ERoutesPath.REVISE_RAPIDO}
            className="tutorial-fast-card"
          >
            <StickyNote2Icon fontSize="medium" style={{ color: "#78909D" }} />
            <Typography color="#78909D">Revise rápido</Typography>
          </S.ButtonLink>
        )}

        {isUserLogged && isPaidActive && (
          <S.ButtonLink
            onClick={handleRestartTutorial}
            className="tutorial-redo"
          >
            <TipsAndUpdatesIcon
              fontSize="medium"
              style={{ color: "#78909D" }}
            />
            <Typography color="#78909D">Tutorial</Typography>
          </S.ButtonLink>
        )}

        {!isPaidActive && (
          <S.ButtonLink onClick={() => handleModal(EModalType.PAYMENT)}>
            <AddShoppingCartIcon
              fontSize="medium"
              style={{ color: "#78909D" }}
            />
            <Typography color="#78909D">Assinar plano</Typography>
          </S.ButtonLink>
        )}

        {isUserLogged && (
          <S.ButtonLink onClick={handleSignOut}>
            <LoginIcon fontSize="medium" style={{ color: "#78909D" }} />
            <Typography color="#78909D">Sair</Typography>
          </S.ButtonLink>
        )}
      </S.MenuItems>
    </S.Container>
  );

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, index, type } = data;

    if (type === EVENTS.STEP_AFTER && index === 0) {
      setExpanded(true);
      setIsActiveTutorial(false);
    }
    if (status === STATUS.PAUSED && currentStep === 1) {
      setTimeout(() => {
        setIsActiveTutorial(true);
      }, 200);
    }
    if (type === EVENTS.STEP_AFTER) {
      setCurrentStep(index + 1);
    }
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      handleFinishTutorial();
    }
  };

  const handleCloseModal = () => {
    setModalActive(undefined);
  };

  const handleRestartTutorial = useCallback(() => {
    handleFinishTutorial();

    setTimeout(() => {
      setIsActiveTutorial(true);
    }, 100);
  }, []);

  useEffect(() => {
    if (triggerTutorial) {
      setCurrentStep(0);
      setIsActiveTutorial(true);
    }
  }, [triggerTutorial]);

  return (
    <>
      <Joyride
        steps={TUTORIAL_STEPS()}
        run={isActiveTutorial}
        stepIndex={currentStep}
        continuous
        showSkipButton
        showProgress
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <Button onClick={toggleDrawer}>
        <DehazeIcon fontSize="large" />
      </Button>
      <Drawer anchor={"left"} open={expanded} onClose={toggleDrawer}>
        <MenuOptions />
      </Drawer>

      {modalActive && (
        <>
          {modalActive === EModalType.LOGIN && (
            <LoginModal modalDismiss={handleCloseModal} />
          )}
          {modalActive === EModalType.REGISTER && (
            <RegisterModal modalDismiss={handleCloseModal} />
          )}
          {modalActive === EModalType.PAYMENT && (
            <PaymentModal modalDismiss={handleCloseModal} />
          )}
        </>
      )}
    </>
  );
};
