

import { PropsWithChildren } from 'react'

import { TTypographyProps } from './types'
import * as S from './styles'

export const Typography = ({ variant = 'default', color = "#000000", align, style, weight, children }: PropsWithChildren<TTypographyProps>) => (
  <S.Container
    variant={variant}
    color={color}
    style={style}
    align={align}
    weight={weight}
  >
    {children}
  </S.Container>
)