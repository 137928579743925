import styled, { css } from "styled-components";
import { TTypographyVariant, TTypographyProps } from "./types";

const textModifiers = (variant: TTypographyVariant) => {
  switch (variant) {
    case "upper":
      return css`
        font-family: "Montserrat", sans-serif;
        font-size: 38px;
        font-weight: 900;
        font-style: normal;

        @media (max-width: 550px) {
          font-size: 30px;
        }
      `;

    case "title":
      return css`
        font-family: "Montserrat", sans-serif;
        font-size: 32px;
        font-weight: 600;
        font-style: normal;

        @media (max-width: 550px) {
          font-size: 28px;
        }
      `;

    case "subTitle":
      return css`
        font-family: "Montserrat", sans-serif;
        font-size: 24px;
        font-weight: 500;
        font-style: normal;

        @media (max-width: 550px) {
          font-size: 16px;
        }
      `;

    case "small":
      return css`
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 300;
        font-style: normal;
      `;

    default:
      return css`
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 16px;

        @media (max-width: 550px) {
          font-size: 14px;
        }
      `;
  }
};

export const Container = styled.p<TTypographyProps>`
  ${({ variant, color, align, weight }) => css`
    ${variant && textModifiers(variant)};

    color: ${color};
    text-align: ${align};
    font-weight: ${weight};
  `}
`;
