import { TFlexProps } from "./types";
import * as S from "./styles";

export const Flex = ({
  direction,
  justifyContent,
  alignItems,
  gap,
  height,
  maxHeight,
  minHeight,
  width,
  maxWidth,
  minWidth,
  style,
  className,
  onClick,
  children,
}: TFlexProps) => {
  return (
    <S.FlexContainer
      direction={direction}
      justifyContent={justifyContent}
      alignItems={alignItems}
      gap={gap}
      height={height}
      maxHeight={maxHeight}
      minHeight={minHeight}
      width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
      style={style}
      className={className}
      onClick={onClick}
    >
      {children}
    </S.FlexContainer>
  );
};
